// We Are Global js here
/* Setting the default slide start index: */
let slideIndex = 1;
/* We call the function that is implemented below: */
showSlides(slideIndex, false);
/* Increase the index by 1 - show the next slide: */
function nextSlide(setFocus) {
    showSlides(slideIndex += 1, setFocus);
}
/* Decrease the index by 1 - show the previous slide: */
function previousSlide(setFocus) {
    showSlides(slideIndex -= 1, setFocus);
}
/* Set the current slide: */
function currentSlide(n) {
    showSlides(slideIndex = n);
}
/* Flip function: */
function showSlides(n, setFocus) {
    let i;
    /* We refer to the elements with the class name "glass", that is, to the pictures: */
    let slides = document.querySelectorAll(".d6-weareg-container .glass");

    /* Checking the number of slides: */
    if (n > slides.length) {
        slideIndex = 1
    }
    if (n < 1) {
        slideIndex = slides.length
    }

    /* Loop through each slide in a for loop: */
    for (let slide of slides) {
        slide.style.display = "none";
        slide.setAttribute('tabindex', '-1');
    }
    /* Making an element block: */
    slides[slideIndex - 1].style.display = "block";
    slides[slideIndex - 1].setAttribute('tabindex', '0');
    if(setFocus) {
        slides[slideIndex - 1].focus();
    }

    if (window.getSelection) {window.getSelection().removeAllRanges();}
        else if (document.selection) {document.selection.empty();}
}

jQuery(function($) {
    $('.d6-global').each(function(index, element) {
        let $glassContainer = $(element).find('.d6-global-container').first();

        let $allSlides = $glassContainer.find('.glass');

        $allSlides.each(function(j, slide) {
            $(slide).attr('tabindex', -1);

            $(slide).on("focus", function(event) {
                $(slide).on('keydown', function(e) {
                    if(e.keyCode == 37) { // left
                        previousSlide(true);
                    }
                    else if(e.keyCode == 39) { // right
                        nextSlide(true);
                    }
                });

                $(this).off(event);
            });
        });

        $allSlides.first().attr('tabindex', '0');
    });
});