jQuery(function($) {

    const heroSliderArgs = {
        dots: true,
        arrows: true,
        slidesToShow: 1,
        infinite: true,
        slidesToScroll: 1,
        fade: true,
        lazyLoad: 'ondemand',

        responsive: [
            {
                breakpoint: 992,
                settings: {
                    dots: true,
                    arrows: true,
                    slidesToShow: 1,
                    infinite: true,
                    slidesToScroll: 1,
                    arrowsPlacement: 'afterSlides'
                }
            }
        ]
    };
    
    $(window).on('load', function() {

        // on init
        // $('.d6-block--hero .slider').on('init', function(event, slick){
        //     $(this).fadeTo(400, 1);
        // });

        $('.d6-block--hero .slider').slick(heroSliderArgs);

        var playBtn = $('.vid-btn');
        playBtn.on('click touch', function() {
            var video = $(this).closest('.vid-btn-container').prev();
            if ($(this).hasClass('paused')) {
                video.trigger('pause');
                $(this).closest('.vid-btn-container').addClass('play-bg');
                $(this).removeClass('paused');
            } else {
                video.trigger('play');
                $(this).closest('.vid-btn-container').removeClass('play-bg');
                $(this).addClass('paused');
            }
        });

        $('.d6-block--hero-animation-enabled.first').each(function() {
            let slides = $(this).find('.slide');
            $(slides).each(function(i, slide) {
                let content = $(slide).find('.content').first();
                let animationContainer = $(slide).find('.content-animation-container').first();
                $(animationContainer).addClass('content-animation-container-opened');

                setTimeout(() => {
                    $(animationContainer).hide(0);
                    $(content).removeClass('content-unanimated');
                }, 1500);
            });
        });
    });



});


// Animation part
jQuery(function($) {

    $(window).on('scroll load', function() {
        $('.d6-block--hero-animation-enabled:not(.first)').each(function() {

            if (isScrolledIntoView($(this), 3)) {

                let slides = $(this).find('.slide');
            
                $(slides).each(function(i, slide) {
                    if (isScrolledIntoView($(slide))) {
                        let content = $(slide).find('.content').first();
                        let animationContainer = $(slide).find('.content-animation-container').first();
                        $(animationContainer).addClass('content-animation-container-opened');

                        setTimeout(() => {
                            $(animationContainer).hide(0);
                            $(content).removeClass('content-unanimated');
                        }, 1500);
                    }
                });

            }
        });

        $('.d6-block--hero-alt-animation-enabled').each(function() {

            if (isScrolledIntoView($(this), 1.5)) {

                $(this).find('.banner-animation-container').addClass('banner-animation-container-opened');
                setTimeout(() => {
                    $(this).addClass('overflow-visible');
                }, 1000);

            }
        });
    });
});