jQuery(function($) {

    $(window).scroll(function() {
        if( isInView($('.d6-block--stats.advanced:not(.disable-animation)')) ) {
            startComponentAnimation();
        } else {
            resetAnimation();
        }
    });

    // adds active class to start the component animation
    function startComponentAnimation() {
        $('.d6-block--stats.advanced').addClass('animated');

        // $('.d6-block--stats-right ul li').each(function(index){
        //     $(this).delay(offsetTime*index).queue(function() { 
        //         $(this).addClass('active').dequeue(); 
        //     });
        // });
    }

    // resets all animations for entire component
    function resetAnimation() {
        $('.d6-block--stats.advanced').removeClass('animated');
    }

    // utility function to check if element is in scrollable view
    function isInView(el) {

        let topOffset = 800; // adjust accordingly
        let bottomOffset = 0;

        let elHeight = $(el).height(); // height of element
        let docViewTop = $(window).scrollTop(); // current distance from the top of page
        let elTop = $(el).offset().top; // distance of the top most part of selected element from the top of the page
        let elBottom = elTop + elHeight; // distance of the bottom most part of selected element from the top of the page
        let windowHeight = window.innerHeight; // height of viewport

        // output/debug statements
        // console.log('elTop = ' + elTop);
        // console.log('elBottom = ' + elBottom);
        // console.log('docViewTop = ' + docViewTop);
        // console.log('windowHeight = ' + windowHeight);
        // console.log('\n\n\n');

        // if component is already in animated state then wait until user is completely out of view before resetting animation/component
        if( el.hasClass('animated') ) {
            return (docViewTop) >= (elTop-windowHeight) && docViewTop <= (elBottom-bottomOffset);
        } else {
            return (docViewTop+topOffset) >= elTop && docViewTop <= (elBottom-bottomOffset);
        }        
    }
});