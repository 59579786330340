// Blog
jQuery(function($) {

    function d6_loadmore(button) {
        
        let postType = $(button).data('posttype');
        let paged = $(button).data('paged');
        let taxonomy = $(button).data('taxonomy');
        let taxonomyid = $(button).data('taxonomyid');
        let posts_per_page = $(button).data('posts_per_page');
        let post_count  = $(button).data('count');
        let search_q  = $(button).data('search');
        
        $.ajax({
            url: d6_ajax_object.ajax_url,
            type: "POST",
            // dataType: "json",
            data: {
                action: 'd6_loadmore_posts',
                nonce: d6_ajax_object.load_more_nonce,
                post_type: postType,
                paged: paged,
                posts_per_page: posts_per_page, //Update this number to reflect how many posts should be loaded with each ajax request
                taxonomy: taxonomy,
                taxonomyid: taxonomyid,
                search_q : search_q
            },
            success: function(data){

                if(data) {
                    let targetGrid = $(button).data('target');
                    $(targetGrid).append(data);

                    let loadedCount = paged * posts_per_page;
                    if(loadedCount >= post_count) {
                        $(button).hide();
                    }
                    else {
                        let newPaged = paged+1;
                        $(button).data('paged', newPaged);
                    }
                }
                // Enable the button again after succseful ajax
                $(button).attr('disabled', false);
            }
        });
    }

    $('#d6-component--post-grid--loadmore').on('click', function(e) {
        e.preventDefault();

        // Disable the button
        $(this).attr('disabled', true);

        d6_loadmore(this);
    });

    // load more search results
    $('.load-more-ctn .load-more').on('click', function(e){
        e.preventDefault();

        let dataRequest = $('.load-more-ctn').attr('data-request');
        let dataPage = $('.load-more-ctn').attr('data-page');

        jQuery.ajax({
            type: 'GET',
            url: d6_ajax_object.ajax_url,
            data: {
                action: 'load_more_search_results',
                'data-request' : dataRequest,
                'data-page' : dataPage,
            },
            success: function(data){

                let returnArray = JSON.parse(data);
                let page = returnArray['page'];
                let searchResults = returnArray['search-results'];
                let totalResults = $('.load-more-ctn').attr('data-total');

                // updating html and adding search results to list
                $('.search-results-ctn .search-item:last-child').after(searchResults);

                let currentNumberOfResults = $('.search-results-ctn .search-item').length;
                $('.search-header-ctn .search-meta span').html( currentNumberOfResults );
                
                // checking to see if any more posts are left to show
                if( currentNumberOfResults < totalResults ) {
                    page++;
                    $('.load-more-ctn').attr('data-page', parseInt(page) );
                } else {
                    $('.search-header-ctn .search-meta span').html( totalResults );
                    $('.load-more-ctn').hide();
                }
                                   
            },
            error: function(){
                console.log('error');
            }
        }) //end ajax call
    }); //end click
    
});