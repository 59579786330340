
// Check if forms actually exists on page to prevent throwing of error when there are no forms at all
let allForms = document.getElementsByTagName("form");
if (Array.isArray(allForms) && allForms.length) {
    
    let id = document.getElementsByTagName("form")[0].id;

    const boxes = Array.from(
        document.getElementsByClassName('large')
    );

    document.getElementById(id).addEventListener("keyup", () => { 
        veedor();
    });

    document.getElementById(id).addEventListener("click", () => { 
        veedor();
    });

    document.addEventListener('click', function(e) {
        actualid = e.target.id;
    }, false);


    function veedor(clic) {
        boxes.forEach(box => {  

            if (box.value.length == 0) {
                box.style.backgroundColor = 'white';
                box.style.backgroundColor = '#000000';     
            } else {
                box.style.backgroundColor = '#c8004e';
                box.style.color = 'white';
            }

            if (box == document.activeElement) {
                box.style.backgroundColor = '#c8004e';
                box.style.color = 'white';
            }
            else {

            }

        });
    }
}

