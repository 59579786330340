jQuery(function($) {

    let windowWidth  = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;

    function d6_set_tabs_height(tabs) {
        let maxHeight = 0;
        $(tabs).each(function(index, tab) {
            if($(tab).height() > maxHeight) {
                maxHeight = $(tab).height();
            }
        });
        return maxHeight;
    }

    // Function to perform tab transitions
    function tabTriggerActivate(tabTrigger) {

        if($(tabTrigger).hasClass('active')) return;

        let thisTrigger = $(tabTrigger).parent('.d6-block--tabbed-tabs-list-item');
        let tabTriggersTargetID = $(thisTrigger).data('target');

        // Remove active from all tab triggers in this group
        let tabsGroup = $(tabTrigger).parents('.d6-block--tabbed-outer');
        let tabsGroupTriggers = $(tabsGroup).find('.d6-block--tabbed-tabs-list-item');
        let tabsGroupItems = $(tabsGroup).find('.d6-block--tabbed-tabs-tabitem');

        $(tabsGroupTriggers).each(function(k, thisTriggerItem) {
            let thisTriggerAnchor = $(thisTriggerItem).find('a').first();
            $(thisTriggerAnchor).attr('tabindex', '-1');
        });

        $(tabsGroupTriggers).removeClass('active');
        
        setTimeout(() => {
            $(thisTrigger).addClass('active');
            animateFakeLine(thisTrigger, 300);
            $(tabTrigger).removeAttr('tabindex');

            // Mobile dropdown stuff
            if(windowWidth < 1024) {
                let tabsGroupDD = $(tabsGroup).find('.d6component--archive-nav-dropdown');
                $(tabsGroupDD).html($(tabTrigger).data('title'));
            }
        });
        
        $(tabsGroupItems).hide(0, function() { 
            setTimeout(() => {
                // Add active class to clicked tab trigger
                $(`#${tabTriggersTargetID}`).show(0, function() {
                    $(`#${tabTriggersTargetID}`).addClass('animated-tabitem');
                });

            }, 100);
        });
    }

    $('.d6-block--tabbed').each(function(i, tabElement) {

        // Set minimum height on tab items upon load
        let tabItems = $(tabElement).find('.d6-block--tabbed-tabs-tabitem');
        $(window).on('load', function() {
            $(tabElement).find('.d6-block--tabbed-tabs').find('.d6-block--tabbed-tabs-tabitem-content').css('min-height', d6_set_tabs_height(tabItems));
            $(tabElement).find('.d6-block--tabbed-tabs-outer').first().height(d6_set_tabs_height(tabItems));
        });
        $(tabItems).hide();

        let tabItemsFirst = $(tabItems).first();
        $(tabItemsFirst).addClass('active');
        $(tabItemsFirst).fadeIn();

        // Mobile dropdown element
        let tabsGroupDD = $(tabElement).find('.d6component--archive-nav-dropdown');
        let tabsGroupAnchorsWrapper = $(tabElement).find('.d6-block--tabbed-tabs-list-wrapper');
        $(tabsGroupDD).on('click', function(e) {
            $(tabsGroupAnchorsWrapper).slideToggle(100);
        });

        // Loop through all tab triggers
        let tabTriggers = $(tabElement).find('.d6-block--tabbed-tabs-list .d6-block--tabbed-tabs-list-item');
        $(tabTriggers).each(function(j, tabTrigger) {

            let tabTriggerAnchor = $(tabTrigger).find('a').first();
            //AC-453: if has active class and device < 1024 then add text to dropdown
            if(windowWidth < 1024 && $(tabTrigger).hasClass('active')) {
                $(tabsGroupDD).html($(tabTriggerAnchor).data('title'));
            }

            // Add events on all tab triggers
            $(tabTriggerAnchor).on('click', function(e) {
                e.preventDefault();
                tabTriggerActivate(this);

                if(windowWidth < 1024) {
                    $(tabsGroupDD).removeClass('opened');
                    $(tabsGroupAnchorsWrapper).slideUp(100);
                }
            });

            // Add keyboard navigation
            $(tabTriggerAnchor).on("focus", function(event) {

                $(tabTriggerAnchor).on('keydown', function(e) {
                    if(e.keyCode == 37) { // left
                        let $prevTrigger = $(tabTrigger).prev();
                        if($prevTrigger.length <= 0) {
                            $(tabTriggers).last().find('a').first().focus();
                            $(tabTriggers).last().find('a').first().click();
                        }
                        else {
                            $prevTrigger.find('a').first().focus();
                            $prevTrigger.find('a').first().click();
                        }
                    }
                    else if(e.keyCode == 39) { // right
                        let $nextTrigger = $(tabTrigger).next();
                        if($nextTrigger.length <= 0) {
                            $(tabTriggers).first().find('a').first().focus();
                            $(tabTriggers).first().find('a').first().click();
                        }
                        else {
                            $nextTrigger.find('a').first().focus();
                            $nextTrigger.find('a').first().click();
                        }
                    }
                });

                $(this).off(event);
            });
        });
    });

    
    // const columnsArgs = {
    //     dots: false,
    //     arrows: false,
    //     infinite: false,
    //     centerMode: false,
    //     variableWidth: true,
    //     autoplay: false,
    //     swipeToSlide: true
    // };
    
    if(windowWidth < 1024) {
        // $('.d6-block--tabbed-tabs-list-wrapper').slick(columnsArgs);
        $('.d6-block--tabbed-tabs-tabitem-content_copy-cta > a').addClass('acosta-button');
    }

    // Animations
    function animateFakeLine(targetTrigger, speed=1000) {

        let tabsOuterContainer = $(targetTrigger).parents('.d6-block--tabbed-outer').first();
        let tabsAnchors = $(tabsOuterContainer).find('.d6-block--tabbed-tabs-list');

        let activeAnchorLeftOffset = $(targetTrigger).find('a').position().left;
        let activeAnchorWidth = $(targetTrigger).find('a').width();
        let fakeLine = $(tabsOuterContainer).find('.d6-block--tabbed-tabs-list-fakeline');

        $(fakeLine).fadeIn(0);
        $(tabsAnchors).removeClass('d6-block--tabbed-tabs-list-linedadded');

        $(fakeLine).stop().animate({
                left: activeAnchorLeftOffset,
                width: activeAnchorWidth,
                opacity: 0.8
            }, speed, function() {
                $(tabsAnchors).addClass('d6-block--tabbed-tabs-list-linedadded');
                $(fakeLine).fadeOut(300);
            }
        );
    }
    $(window).on('scroll load', function() {
        $('.d6-block--tabbed').each(function() {

            if (isScrolledIntoView($(this)) && !$(this).hasClass('d6-block--tabbed-no-animation') ) {

                $(this).find('.d6-block--tabbed-section-header > h2').addClass('title-reveal-animation');

                let tabs = $(this).find('.d6-block--tabbed-tabs');

                let tabsOuterContainer = $(tabs).parents('.d6-block--tabbed-outer');
                let tabsAnchors = $(tabsOuterContainer).find('.d6-block--tabbed-tabs-list');
                let activeAnchor = $(tabsAnchors).find('.d6-block--tabbed-tabs-list-item.active');
                
                animateFakeLine(activeAnchor, 1000);


                let tabAnimationDelay = 1500;
                if($(this).hasClass('d6-block--tabbed-sidenav') ) {
                    tabAnimationDelay = 500;
                }
                
                setTimeout(() => {
                    $(tabs).find('.d6-block--tabbed-tabs-tabitem.active').addClass('animated-tabitem');
                }, tabAnimationDelay);
            }
        });
    });
});