$ = jQuery;

// Animation helper
function isScrolledIntoView(elem, factor=2) {
    let docViewTop = $(window).scrollTop();
    let docViewBottom = docViewTop + window.innerHeight;

    let elemTop = $(elem).offset().top;

    // Animate when '$(elem).height() / factor' or more of item is in view port
    let elemBottom = elemTop + ($(elem).height() / factor);

    return ((elemBottom <= docViewBottom) && (elemTop >= docViewTop));
}

jQuery(function($) {
    // Back top top button functionality
    $('#backtotop').hide();
    $(window).scroll(function(event){

        let windowWidth = window.innerWidth;
        let scrollTopTriggerPoint = 2800;

        if(windowWidth <= 1023) {
            scrollTopTriggerPoint = 1200;
        }

        let totalScroll = $(window).scrollTop();
        if (totalScroll > scrollTopTriggerPoint) {
            $('#backtotop').fadeIn();
        }
        else {
            $('#backtotop').fadeOut();
        }
    });

    $("#backtotop-a").click(function(e) {
        e.preventDefault();
        $("html, body").animate({ scrollTop: 0 }, "slow");
        return false;
    });

    // Dropdown archive menu
    $('.d6component--archive-nav-dropdown').click(function() {
        $(this).toggleClass('opened');
        let navParent = $(this).parent('.d6component--archive-nav-container');
        let navDD = $(navParent).find('.d6component--archive-nav-list');
        $(navDD).slideToggle(100);
    });

    $('.d6component--archive-nav-container').each(function(index, element) {
        let activeText = $(this).find('a.active').first().text() + ' &nbsp;';
        $(this).find('.d6component--archive-nav-dropdown').html(activeText);
    });

    // Webinars count
    if( $('.webinar-search--count').length > 0 ) {
        if($('#webinar-post-count').val() <= 0 || typeof($('#webinar-post-count').val()) == 'undefined') {
            $('.webinar-search--count').text('No webinars found for your search.');
        }
        else if($('#webinar-post-count').val() == 1) {
            $('.webinar-search--count').text($('#webinar-post-count').val() + ' webinar found for your search.');
        }
        else {
            $('.webinar-search--count').text($('#webinar-post-count').val() + ' webinars found for your search.');
        }
    }

    // Animations stuff
    $(window).on('scroll load', function() {
        $('.acosta-animate').each(function() {
            if (isScrolledIntoView($(this))) {
                let animateClass = $(this).data('animate-class');
                $(this).addClass(animateClass);
            }
        });
    });

    // Header Search Bar
    $('#header__nav_search_btn').on('click', function() {
        $('#header__nav_search').removeClass('search-hidden');
        $('#header__nav_search--bar-text').focus();
    });

    $('#header__nav_search--bar-btn-close').on('click', function() {
        $('#header__nav_search').addClass('search-hidden');
    });
});

jQuery(function($) {
    $(document).ready(function() {

        var windowWidth = window.innerWidth;

        if(windowWidth < 1023) {
            $('#header__nav_search').hide();
        }

        // setting unique id for resize event
        var waitForFinalEvent = (function () {
            var timers = {};
            return function (callback, ms, uniqueId) {
              if (!uniqueId) {
                uniqueId = "Don't call this twice without a uniqueId";
              }
              if (timers[uniqueId]) {
                clearTimeout (timers[uniqueId]);
              }
              timers[uniqueId] = setTimeout(callback, ms);
            };
        })();


        $('.hamburger-menu').on('click',function(e){
            e.preventDefault();
            $('#header__nav_search').slideDown();
            $('.header__content').addClass('open');
            $('body').addClass('viewport').promise().done(function() {
                $('.header__nav').slideToggle();
            });
        });

        $('.close-menu').on('click',function(e){
            e.preventDefault();
            $('.header__nav').slideToggle().promise().done(function() {
                $('body').removeClass('viewport');
                $('#header__nav_search').slideUp(0).promise().done(function() {
                    $('.header__content').removeClass('open');
                });
            });
        });

        
        
        // Hide Header on on scroll down
        var didScroll;
        var lastScrollTop = 0;
        var delta = 5;
        var navbarHeight = $('header').outerHeight();
        var navAnimationRunning = false;

        $(window).scroll(function(event){
            var scrollWidth = window.innerWidth;
            if (scrollWidth > 991) {
                didScroll = true;
            }
        });

        setInterval(function() {
            if (didScroll && !navAnimationRunning) {
                navAnimationRunning = true;
                hasScrolled();
                didScroll = false;
                navAnimationRunning = false;
            }
        }, 500);

        function navReveal() {
            $('.header__content').removeClass('nav-up').addClass('nav-down').promise().done(function() {
                setTimeout(function() {
                    $('.header__sub-logo').removeClass('slideInPos').addClass('staticPos').promise().done(function() {
                        setTimeout(function() {
                            $('.header__content--main-logo').fadeIn(200);
                            $('.header__nav').fadeIn(200).promise().done(function() {
                                $('.header__sub-logo').fadeOut(200);
                            });
                        }, 250);
                    });
                    $('.header.header--scrolled').removeClass('header--scrolled');
                }, 200);
            });
        }

        function navCollapse() {
            $('.header__content--main-logo').fadeOut(200);
            $('.header__nav').fadeOut(200);
            $('.header__sub-logo').fadeIn(200).promise().done(function() {
                $('.header__sub-logo').removeClass('staticPos').addClass('slideInPos').promise().done(function() {
                    setTimeout(function() {
                        $('.header__content').removeClass('nav-down').addClass('nav-up');
                    }, 200);
                });
            });
            $('.header').addClass('header--scrolled');
        }

        $('.header__sub-logo').on('mouseenter', function() {
            navReveal();
        });

        function hasScrolled() {
            var st = $(window).scrollTop();
            
            if(Math.abs(lastScrollTop - st) <= delta)
                return;
                
            if (st > lastScrollTop && st > navbarHeight){
                // Scroll Down
                navCollapse();
            } else {
                // Scroll Up
                navReveal();
            }
            
            lastScrollTop = st;
        }


        (function( $ ) {

            /**
             * initMap
             *
             * Renders a Google Map onto the selected jQuery element
             *
             * @date    22/10/19
             * @since   5.8.6
             *
             * @param   jQuery $el The jQuery element.
             * @return  object The map instance.
             */
            function initMap( $el ) {
            
                // Find marker elements within map.
                var $markers = $el.find('.marker');
            
                // Create gerenic map.
                var mapArgs = {
                    zoom        : $el.data('zoom') || 16,
                    mapTypeId   : google.maps.MapTypeId.ROADMAP
                };
                var map = new google.maps.Map( $el[0], mapArgs );
            
                // Add markers.
                map.markers = [];
                $markers.each(function(){
                    initMarker( $(this), map );
                });
            
                // Center map based on markers.
                centerMap( map );
            
                // Return map instance.
                return map;
            }
            
            /**
             * initMarker
             *
             * Creates a marker for the given jQuery element and map.
             *
             * @date    22/10/19
             * @since   5.8.6
             *
             * @param   jQuery $el The jQuery element.
             * @param   object The map instance.
             * @return  object The marker instance.
             */
            function initMarker( $marker, map ) {
            
                // Get position from marker.
                var lat = $marker.data('lat');
                var lng = $marker.data('lng');
                var latLng = {
                    lat: parseFloat( lat ),
                    lng: parseFloat( lng )
                };
            
                // Create marker instance.
                var marker = new google.maps.Marker({
                    position : latLng,
                    map: map
                });
            
                // Append to reference for later use.
                map.markers.push( marker );
            
                // If marker contains HTML, add it to an infoWindow.
                if( $marker.html() ){
            
                    // Create info window.
                    var infowindow = new google.maps.InfoWindow({
                        content: $marker.html()
                    });
            
                    // Show info window when marker is clicked.
                    google.maps.event.addListener(marker, 'click', function() {
                        infowindow.open( map, marker );
                    });
                }
            }
            
            /**
             * centerMap
             *
             * Centers the map showing all markers in view.
             *
             * @date    22/10/19
             * @since   5.8.6
             *
             * @param   object The map instance.
             * @return  void
             */
            function centerMap( map ) {
            
                // Create map boundaries from all map markers.
                var bounds = new google.maps.LatLngBounds();
                map.markers.forEach(function( marker ){
                    bounds.extend({
                        lat: marker.position.lat(),
                        lng: marker.position.lng()
                    });
                });
            
                // Case: Single marker.
                if( map.markers.length == 1 ){
                    map.setCenter( bounds.getCenter() );
            
                // Case: Multiple markers.
                } else{
                    map.fitBounds( bounds );
                }
            }
            
            // Render maps on page load.
            $(document).ready(function(){
                $('.acf-map').each(function(){
                    var map = initMap( $(this) );
                });
            });
            
            })(jQuery);

        // Ajax function
        // $('.delete-customer').on('click', function(e){
        //     e.preventDefault();
        //     var postID = $(this).attr('data-post');
        //     if (confirmation) {
        //         jQuery.ajax({
        //             type: 'GET',
        //             url: ajax_events.ajax_url,
        //             data: {
        //                 action: 'delete_user',
        //                 'data-postId' : postID,
        //             },
        //             success: function(data){
        //                 console.log('success');
        //             },
        //             error: function(){
        //                 console.log('error');
        //             }
        //         }) //end ajax call
        //     } //end confirm
        // }); //end click

        
        /*
        *   This content is licensed according to the W3C Software License at
        *   https://www.w3.org/Consortium/Legal/2015/copyright-software-and-document
        *
        *   Supplemental JS for the disclosure menu keyboard behavior
        */

        'use strict';

        class headerMenu {
        constructor(domNode) {
            this.rootNode = domNode;
            this.controlledNodes = [];
            this.openIndex = null;
            this.useArrowKeys = true;
            this.topLevelNodes = [
            ...this.rootNode.querySelectorAll(
                '.main-link, button[aria-expanded][aria-controls]'
            ),
            ];

            this.topLevelNodes.forEach((node) => {
            // handle button + menu
            if (
                node.tagName.toLowerCase() === 'button' &&
                node.hasAttribute('aria-controls')
            ) {
                const menu = node.parentNode.querySelector('ul');
                if (menu) {
                    // save ref controlled menu
                    this.controlledNodes.push(menu);

                    // collapse menus
                    node.setAttribute('aria-expanded', 'false');
                    this.toggleMenu(menu, false);

                    // attach event listeners
                    menu.addEventListener('keydown', this.onMenuKeyDown.bind(this));
                    node.addEventListener('click', this.onButtonClick.bind(this));
                    node.addEventListener('keydown', this.onButtonKeyDown.bind(this));
                }
            }
            // handle links
            else {
                this.controlledNodes.push(null);
                node.addEventListener('keydown', this.onLinkKeyDown.bind(this));
            }
            });

            this.rootNode.addEventListener('focusout', this.onBlur.bind(this));
        }

        controlFocusByKey(keyboardEvent, nodeList, currentIndex) {
            switch (keyboardEvent.key) {
            case 'ArrowUp':
            case 'ArrowLeft':
                keyboardEvent.preventDefault();
                if (currentIndex > -1) {
                var prevIndex = Math.max(0, currentIndex - 1);
                nodeList[prevIndex].focus();
                }
                break;
            case 'ArrowDown':
            case 'ArrowRight':
                keyboardEvent.preventDefault();
                if (currentIndex > -1) {
                var nextIndex = Math.min(nodeList.length - 1, currentIndex + 1);
                nodeList[nextIndex].focus();
                }
                break;
            case 'Home':
                keyboardEvent.preventDefault();
                nodeList[0].focus();
                break;
            case 'End':
                keyboardEvent.preventDefault();
                nodeList[nodeList.length - 1].focus();
                break;
            }
        }

        // public function to close open menu
        close() {
            this.toggleExpand(this.openIndex, false);
        }

        onBlur(event) {
            var menuContainsFocus = this.rootNode.contains(event.relatedTarget);
            if (!menuContainsFocus && this.openIndex !== null) {
            this.toggleExpand(this.openIndex, false);
            }
        }

        onButtonClick(event) {
            var button = event.target;
            var buttonIndex = this.topLevelNodes.indexOf(button);
            var buttonExpanded = button.getAttribute('aria-expanded') === 'true';
            this.toggleExpand(buttonIndex, !buttonExpanded);
        }

        onButtonKeyDown(event) {
            var targetButtonIndex = this.topLevelNodes.indexOf(document.activeElement);

            // close on escape
            if (event.key === 'Escape') {
            this.toggleExpand(this.openIndex, false);
            }

            // move focus into the open menu if the current menu is open
            else if (
            this.useArrowKeys &&
            this.openIndex === targetButtonIndex &&
            event.key === 'ArrowDown'
            ) {
            event.preventDefault();
            this.controlledNodes[this.openIndex].querySelector('a').focus();
            }

            // handle arrow key navigation between top-level buttons, if set
            else if (this.useArrowKeys) {
            this.controlFocusByKey(event, this.topLevelNodes, targetButtonIndex);
            }
        }

        onLinkKeyDown(event) {
            var targetLinkIndex = this.topLevelNodes.indexOf(document.activeElement);

            // handle arrow key navigation between top-level buttons, if set
            if (this.useArrowKeys) {
            this.controlFocusByKey(event, this.topLevelNodes, targetLinkIndex);
            }
        }

        onMenuKeyDown(event) {
            if (this.openIndex === null) {
            return;
            }

            var menuLinks = Array.prototype.slice.call(
            this.controlledNodes[this.openIndex].querySelectorAll('a')
            );
            var currentIndex = menuLinks.indexOf(document.activeElement);

            // close on escape
            if (event.key === 'Escape') {
            this.topLevelNodes[this.openIndex].focus();
            this.toggleExpand(this.openIndex, false);
            }

            // handle arrow key navigation within menu links, if set
            else if (this.useArrowKeys) {
            this.controlFocusByKey(event, menuLinks, currentIndex);
            }
        }

        toggleExpand(index, expanded) {
            // close open menu, if applicable
            if (this.openIndex !== index) {
            this.toggleExpand(this.openIndex, false);
            }

            // handle menu at called index
            if (this.topLevelNodes[index]) {
            this.openIndex = expanded ? index : null;
            this.topLevelNodes[index].setAttribute('aria-expanded', expanded);
            this.toggleMenu(this.controlledNodes[index], expanded);
            }
        }

        toggleMenu(domNode, show) {
            if (domNode) {
            domNode.style.display = show ? 'block' : 'none';
            }
        }

        updateKeyControls(useArrowKeys) {
            this.useArrowKeys = useArrowKeys;
        }
        }

        /* Initialize Disclosure Menus */
        if(window.innerWidth < 1023) {
            initTheMenu()
        }
        else {
            window.addEventListener(
                'load',
                initTheMenu,
                false
            );
        }
        
        function initTheMenu() {
            var menus = document.querySelectorAll('.header__menu');
            var disclosureMenus = [];

            for (var i = 0; i < menus.length; i++) {
                disclosureMenus[i] = new headerMenu(menus[i]);
            }

            // listen to arrow key checkbox
            var arrowKeySwitch = document.getElementById('arrow-behavior-switch');
            if (arrowKeySwitch) {
                arrowKeySwitch.addEventListener('change', function () {
                    var checked = arrowKeySwitch.checked;
                    for (var i = 0; i < disclosureMenus.length; i++) {
                        disclosureMenus[i].updateKeyControls(checked);
                    }
                });
            }

            // fake link behavior
            disclosureMenus.forEach((headerMenu, i) => {
                var links = menus[i].querySelectorAll('[href="#mythical-page-content"]');
                var examplePageHeading = document.getElementById('mythical-page-heading');
                for (var k = 0; k < links.length; k++) {
                    // The codepen export script updates the internal link href with a full URL
                    // we're just manually fixing that behavior here
                    links[k].href = '#mythical-page-content';

                    links[k].addEventListener('click', (event) => {
                        // change the heading text to fake a page change
                        var pageTitle = event.target.innerText;
                        examplePageHeading.innerText = pageTitle;

                        // handle aria-current
                        for (var n = 0; n < links.length; n++) {
                            links[n].removeAttribute('aria-current');
                        }
                        event.target.setAttribute('aria-current', 'page');
                    });
                }
            });
        }

    }); //end ready
}); // end jquery



// events overview functionality js
jQuery(function($) {

    // removing border for mobile
    function setNoBorderClass() {
        if( $('.tribe-events-l-container').length ) {
            $('.tribe-events-calendar-list .tribe-events-calendar-list__month-separator').each(function(){
                if( $(this).index() != 0 ) {
                    $(this).prev().addClass('no-border');
                }
            });
        }
    }
    setNoBorderClass();

    // setting the aria label for accessibility
    function setDatePickerAria() {
        if( $('.tribe-events-l-container').length ) {

            $('#events-search-icon').click(function(){
                console.log('im clicked here');
                //$('.tribe-events-c-search__button').trigger('click');
            });

            $('.tribe-events-c-top-bar__datepicker-button').each(function(){

                let startText = $(this).find('time:first-child .tribe-events-c-top-bar__datepicker-desktop').html().trim();
                let endText = $(this).find('time:nth-child(3) .tribe-events-c-top-bar__datepicker-desktop').html().trim();
                let finalText = startText + ' ' + endText;

                $(this).attr('aria-label', finalText);
            });
        }
    }
    setDatePickerAria();

    $(document).ajaxStart(function() {
        setTimeout(function(){
            setNoBorderClass();
            setDatePickerAria();
        }, 1000);
    });
});